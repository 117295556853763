import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment  } from 'environments/environment';
import * as crypto from 'crypto-js';
import { Observable } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root'
})
export class DataManageService {
  
  rc_url: string = environment.upload_image_url;
  //dev environment url
  url: string = environment.be_url;
  //production environment url
  // url: string = "https://box.bluemythglobal.com/bluebox/bluebox";

  constructor(private http: HttpClient,
    private analytics:AngularFireAnalytics) { }


  encryptMsg(jsonInput, i_key?, i_iv?){
    function encryptByDES(word){
      var key;
      var iv;
      if(i_key && i_iv){
        key = crypto.enc.Latin1.parse(i_key);
        iv = crypto.enc.Latin1.parse(i_iv);
      }else{
        key = crypto.enc.Latin1.parse(environment.key);
        iv = crypto.enc.Latin1.parse(environment.iv);
      }


      let encrypted = crypto.AES.encrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: crypto.pad.ZeroPadding });

      return encrypted.toString();
    }

    var temp = btoa(encryptByDES(JSON.stringify(jsonInput)));
    return temp;
  }

  encryptByDES(word, i_key?, i_iv?){
    var key;
    var iv;
    if(i_key && i_iv){
      key = crypto.enc.Latin1.parse(i_key);
      iv = crypto.enc.Latin1.parse(i_iv);
    }else{
      key = crypto.enc.Latin1.parse(environment.key);
      iv = crypto.enc.Latin1.parse(environment.iv);
    }

    let encrypted = crypto.AES.encrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: crypto.pad.ZeroPadding });

    return encrypted.toString();
  }

  decryptMsg(msg, i_key?, i_iv?){
    function decryptByDES(word, i_key?, i_iv?){
      //console.log(word)
      var key;
      var iv;
      if(i_key && i_iv){
        key = crypto.enc.Latin1.parse(i_key);
        iv = crypto.enc.Latin1.parse(i_iv);
      }else{
        key = crypto.enc.Latin1.parse(environment.key);
        iv = crypto.enc.Latin1.parse(environment.iv);
      }
      // var key = crypto.enc.Latin1.parse(environment.key);
      // var iv = crypto.enc.Latin1.parse(environment.iv);
      word = word.toString();
      let decrypt = crypto.AES.decrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: crypto.pad.ZeroPadding });

      let decryptedStr = decrypt.toString(crypto.enc.Utf8);

      return decryptedStr.toString();
    }

    var temp = decryptByDES(atob(msg).split('\n').join(''), i_key, i_iv);
    return temp;
  }

  bm_http_post_with_image(URL,PARAMS,IMG_FILE):Observable<any>
{
    const requestOptions: Object = {
      responseType: 'text',
      'Access-Control-Allow-Origin':'*',
    }
    var formData = new FormData();
    for (var key in PARAMS)
    {
        formData.append(key, encodeURIComponent(PARAMS[key]));
    }
    formData.append('img', IMG_FILE);
    return this.http.post<string>(this.rc_url, formData, requestOptions);
    // .toPromise().then(
    //   res=>{
    //     console.log(res);
    //   }
    // ).catch(err=>{
    //   console.log(err);
    // });

}
  

  http_request(pram): Observable<any>{


    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    var temp = this.processPram(pram);
    let temp_date = new Date();
    let date = temp_date.getFullYear() + '-' + (temp_date.getMonth()+1) + '-' + temp_date.getDate() + ' ' + temp_date.getHours() + ':' + temp_date.getMinutes();
    if(pram.act != ''){
      this.analytics.logEvent('api_call', {'time': date, 'act': pram.act});
    }else{
      this.analytics.logEvent('invalid-api-call', {'time': date, 'pram': pram.par});
    }


    return this.http.post<string>(this.url, temp, requestOptions);
    // .toPromise().then(
    //   res => {
    //     solveReturnValue(res)
    //   }
    // ).catch(err=> console.log(err));

  }



  processPram(pram){
    var str = "";
    for (let key in pram){
      if(str!=""){
        str+="&";
      }
      str += key + "=" + encodeURIComponent(pram[key]);
    }
    return str;
  }

  processImageRequest(url, act, pram, img){
    var url_encode = btoa(this.encryptByDES(JSON.stringify(pram)));
    this.bm_http_post_with_image(url,{act:act,m:1,par:url_encode},img);
  }


  communityRequest(func, type, pram?): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    switch(type){
      case 'get':
        return this.http.get(environment.communityUrl+func, requestOptions);
      case 'post':
        return this.http.post(environment.communityUrl+func, pram, requestOptions);
      case 'delete':
        return this.http.delete(environment.communityUrl+func, requestOptions);
      case 'put':
        return this.http.put(environment.communityUrl+func, pram, requestOptions);
    }
  }

  commentsRequest(func, type, pram?): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    switch(type){
      case 'post':
        return this.http.post(environment.commentsUrl+func, pram, requestOptions);
    }
  }

  communitySysRequest(func, type, pram?): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text',
    }
    switch(type){
      case 'post':
        return this.http.post(environment.communitySysUrl+func, pram, requestOptions);
    }
  }



  encryptByDES2(word){
    var key = crypto.enc.Latin1.parse(environment.key2);
    var iv = crypto.enc.Latin1.parse(environment.iv2);

    let encrypted = crypto.AES.encrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: 1 });

    return encrypted.toString();
  }

}
